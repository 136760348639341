import { useRecoilValue } from 'recoil';
import { loadingState } from '../stores/loadingStore';

// components
import Modal from '../components/modal/modal';

// style
import styles from './loading.module.scss';

// img
import logoSymbol1 from '../assets/img/loader/logo_symbol1.svg';
import logoSymbol2 from '../assets/img/loader/logo_symbol2.svg';
import logoSymbol3 from '../assets/img/loader/logo_symbol3.svg';

function Loading() {
  const loading = useRecoilValue(loadingState);
  if (!loading) return null;
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal close={() => {}}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <img src={logoSymbol1} alt="icon" className={styles.item} />
          <img src={logoSymbol2} alt="icon" className={styles.item} />
          <img src={logoSymbol3} alt="icon" className={styles.item} />
        </div>
      </div>
    </Modal>
  );
}

export default Loading;
