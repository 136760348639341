import { motion } from 'framer-motion';
import ModalPortal from '../../ModalPortal';

// style
import styles from './modal.module.scss';

function Modal({ close, children }: { close: React.MouseEventHandler<HTMLDivElement>; children: React.ReactNode }) {
  return (
    <ModalPortal>
      <div className={styles.container}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'linear' }}
          exit={{ opacity: 0 }}
          className={styles.bg}
          onClick={close}
        ></motion.div>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}

export default Modal;
