import { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { foldState } from './stores/layoutStore';

// include
import Header from './includes/Header';
import Loading from './includes/Loading';
import Main from './includes/Main';
import Content from './includes/Content';
import Aside from './includes/Aside';

// page
// 홈
import Home from './routes';
// 로그인
import Login from './routes/login';
import PasswordChange from './routes/login/change';
// CDN
import Server from './routes/cdn';
import GroupManage from './routes/cdn/groupManage';
import ServerManage from './routes/cdn/serverManage';
// vod
import Vod from './routes/vod';
import VodManage from './routes/vod/manage';
import VodTrans from './routes/vod/trans';
import PageNotFound from './routes/pageNotFound/PageNotFound';

// modal
import Alert from './components/modal/alert';
import Confirm from './components/modal/confirm';
import Prompt from './components/modal/prompt';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('message', message => {
      if (typeof message.data === 'string' && message.data.includes('linkPage_'))
        navigate(message.data.replace('linkPage_', ''));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { pathname } = useLocation();

  const setFold = useSetRecoilState(foldState);
  useEffect(() => {
    setFold(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <>
      <Header />
      <Main>
        <Aside />
        <Content>
          <Routes>
            {/* 메인 */}
            <Route path="/web/" element={<Home />} />
            {/* 로그인 */}
            <Route path="/web/login" element={<Login />} />
            <Route path="/web/login/change" element={<PasswordChange />} />
            {/* CDN */}
            <Route path="/web/cdn" element={<Server />} />
            <Route path="/web/cdn/groupManage" element={<GroupManage />} />
            <Route path="/web/cdn/serverManage" element={<ServerManage />} />
            {/* VOD */}
            <Route path="/web/vod" element={<Vod />} />
            <Route path="/web/vod/manage" element={<VodManage />} />
            <Route path="/web/vod/trans" element={<VodTrans />} />
            {/* 404 */}
            <Route path="/web/*" element={<PageNotFound />} />
          </Routes>
        </Content>
      </Main>
      <Alert />
      <Confirm />
      <Prompt />
      <Loading />
      <span style={{ display: 'none' }}>version 0.0.1</span>
    </>
  );
}

export default App;
