import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';
import { promptState, usePrompt } from '../../stores/promptStore';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Prompt() {
  const prompt = useRecoilValue(promptState);
  const { promptCheck, promptClose } = usePrompt();
  const [value, setValue] = useState('');

  return (
    <AnimatePresence>
      {prompt.state && (
        <Modal close={() => promptClose()}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>알림</div>
            <div className={styles.body}>
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: prompt.message }}></div>
              <div className={styles.input}>
                <input
                  type="text"
                  placeholder={prompt.placeholder || ''}
                  onChange={e => setValue(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter' || e.code === 'Enter') promptCheck(value);
                  }}
                />
              </div>
              <div className={styles.btns}>
                <div className={styles.btn} onClick={() => promptCheck(value)}>
                  확인
                </div>
                <div className={styles.btn} onClick={() => promptClose()}>
                  취소
                </div>
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Prompt;
