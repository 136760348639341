import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getServerList, deleteServer } from '../../api/cdn';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';

// components
import Page from '../../includes/Page';

// img
import { groupSelectorState } from '../../stores/cdnStore';
import GroupSelector from '../../components/cdn/groupSelector';

function CdnServer() {
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [groupSelector, setGroupSelector] = useRecoilState(groupSelectorState);
  useEffect(() => {
    setGroupSelector({
      ...groupSelector,
      state: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [data, setData] = useState<IServer[]>([]);
  const { isLoading: getServerLoading, refetch } = useQuery<IServer[]>(
    ['server', groupSelector.data.cg_key],
    () => getServerList(Number(groupSelector.data.cg_key)),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => setData(e.map(item => ({ ...item, checked: false }))),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => alertShow(error.response.data.message),
    },
  );
  const { mutate, isLoading: deleteLoading } = useMutation(deleteServer, {
    onSuccess(data, variables) {
      if (variables.chain) return;
      refetch();
    },
  });

  const selectDelete = () => {
    confirmShow('선택한 서버를 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) {
        mutate({ key: data[i].cs_key, chain: i !== checked.length - 1 });
      }
    });
  };

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(getServerLoading || deleteLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServerLoading, deleteLoading]);

  const allSelect = () => {
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );
  };
  return (
    <Page title="CDN서버목록">
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>전체선택</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate('/web/cdn/serverManage')}>
            <span>등록</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>선택삭제</span>
          </button>
        </div>
        <div
          className="filter"
          onClick={() =>
            setGroupSelector({
              ...groupSelector,
              state: true,
            })
          }
        >
          <div className="selector">
            <span>{groupSelector.data.cg_name || '그룹 선택'}</span>
          </div>
          <div className="arrow"></div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th></th>
            <th>아이피</th>
            <th>상태</th>
            <th>아이디</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={6}>
                <div>검색된 서버가 없습니다.</div>
              </td>
            </tr>
          )}
          {data.map(item => (
            <tr key={item.cs_key}>
              <td>
                <div>
                  <div
                    className={classNames(
                      'checkbox',
                      data.filter(ele => ele.cs_key === item?.cs_key)[0]?.checked && 'active',
                    )}
                    onClick={() => {
                      setData(
                        data.map(ele => {
                          if (ele.cs_key === item.cs_key) return { ...ele, checked: !ele.checked };
                          return ele;
                        }),
                      );
                    }}
                  ></div>
                </div>
              </td>
              <td>
                <div>
                  <span>{item.cs_ip}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{item.cs_status}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{item.cs_instance_id}</span>
                </div>
              </td>
              <td>
                <div>
                  <button
                    type="button"
                    className="primary"
                    onClick={() => navigate(`/web/cdn/serverManage?csKey=${item.cs_key}`)}
                  >
                    <span>수정</span>
                  </button>
                  <button
                    type="button"
                    className="secondary"
                    onClick={() => confirmShow('서버를 삭제하시겠습니까?', () => mutate({ key: item.cs_key }))}
                  >
                    <span>삭제</span>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <GroupSelector />
    </Page>
  );
}

export default CdnServer;
