import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { getVodTransList } from '../../api/vod';
import { useAlert } from '../../stores/alertStore';
import { loadingState } from '../../stores/loadingStore';
import { useVodInfo } from '../../stores/vodStore';

// components
import Page from '../../includes/Page';
import VodInfo from '../../components/vod/vodInfo';

function VodTrans() {
  const { alertShow } = useAlert();
  const [data, setData] = useState<IVodTrans[]>([]);
  const { isLoading } = useQuery<IVodTrans[]>(['vod'], getVodTransList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setData(e.map(item => ({ ...item, checked: false })));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { setVodInfo } = useVodInfo();
  return (
    <Page title="변환중 VOD">
      <div className="table-tools"></div>
      <table className="table">
        <thead>
          <tr>
            <th>파일명</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr>
              <td colSpan={2}>
                <div>검색된 VOD가 없습니다.</div>
              </td>
            </tr>
          )}
          {data?.map(item => (
            <tr key={item?.vodts_key}>
              <td>
                <div>
                  <span
                    style={{ cursor: 'pointer', color: 'var(--tertiary)' }}
                    onClick={() =>
                      setVodInfo({
                        state: true,
                        title: 'VOD 정보',
                        data: {
                          vod_key: item?.vod_key?.vod_key,
                          vod_name: item?.vod_key?.vod_name,
                          vod_origin_name: item?.vod_key?.vod_origin_name,
                          vod_status: item?.vod_key?.vod_status,
                          vod_file_type: item?.vod_key?.vod_file_type,
                          vod_transcoder_ip: item?.vod_key?.vod_transcoder_ip,
                          vod_path: item?.vod_key?.vod_path,
                          created_at: item?.vod_key?.created_at,
                          updated_at: item?.vod_key?.updated_at,
                        },
                      })
                    }
                  >
                    {item?.vod_key?.vod_origin_name}
                  </span>
                </div>
              </td>
              <td>
                <div>
                  {item?.vod_key?.vod_status === 'ready' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--btn-success)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      대기
                    </span>
                  )}
                  {item?.vod_key?.vod_status === 'transcoding' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--btn-warning)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      변환중
                    </span>
                  )}
                  {item?.vod_key?.vod_status === 'complete' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--greyish6)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      완료
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <VodInfo />
    </Page>
  );
}

export default VodTrans;
