import { atom, useRecoilState } from 'recoil';

export const alertState = atom<IAlert>({
  key: 'alert',
  default: {
    state: false,
    message: '',
    func: null,
  },
});

export function useAlert() {
  const [alert, setAlert] = useRecoilState(alertState);

  const alertShow = (msg: string, fnc?: (() => void) | null) =>
    setAlert({ state: true, message: msg, func: fnc ?? null });

  const alertClose = () => {
    if (alert.func) alert.func();
    setAlert({ state: false, message: '', func: null });
  };

  return { alertShow, alertClose };
}
