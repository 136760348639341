import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import queryString from 'query-string';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createServer, getServerDetail, patchServer } from '../../api/cdn';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { groupSelectorState, useGroupSelector } from '../../stores/cdnStore';
import { loadingState } from '../../stores/loadingStore';

// components
import Page from '../../includes/Page';
import FormItem from '../../components/basic/FormItem';
import GroupSelector from '../../components/cdn/groupSelector';

function CdnServerManage() {
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const { setGroupSelector } = useGroupSelector();
  const groupSelector = useRecoilValue(groupSelectorState);

  const { search } = useLocation();
  const { csKey } = queryString.parse(search);

  const [ip, seIp] = useState('');

  const { isLoading: getLoading } = useQuery<IServer>(['groupDetail'], () => getServerDetail(Number(csKey)), {
    refetchOnWindowFocus: false,
    enabled: typeof csKey === 'string',
    onSuccess: e => {
      setGroupSelector({ state: false, data: { cg_key: e.cg_key?.cg_key ?? 0, cg_name: e.cg_key?.cg_name ?? '' } });
      seIp(e.cs_ip);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const init = () => {
    setGroupSelector({ state: false, data: { cg_key: null, cg_name: '' } });
    seIp('');
  };
  useEffect(() => {
    if (typeof csKey !== 'string') init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create, isLoading: createLoading } = useMutation(createServer, {
    onSuccess: () => alertShow('서버가 등록되었습니다.', init),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(patchServer, {
    onSuccess: () => alertShow('서버가 수정되었습니다.', () => navigate('/web/cdn')),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(getLoading || createLoading || updateLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLoading, createLoading, updateLoading]);

  return (
    <Page title={typeof csKey === 'string' ? 'CDN서버수정' : 'CDN서버등록'}>
      <FormItem title="그룹" essential>
        <div className="input">
          <input
            type="text"
            placeholder="그룹"
            value={groupSelector.data.cg_name}
            readOnly
            onClick={() =>
              setGroupSelector({
                ...groupSelector,
                state: true,
              })
            }
          />
        </div>
      </FormItem>
      <FormItem title="아이피" essential>
        <div className="input">
          <input type="text" placeholder="아이피" value={ip} onChange={e => seIp(e.target.value)} />
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!groupSelector.data.cg_key || ip === '') {
              alertShow('빈 항목을 입력하십시오.', null);
              return;
            }
            if (typeof csKey === 'string') {
              confirmShow('서버를 수정하시겠습니까?', () =>
                update({ key: Number(csKey), group: Number(groupSelector.data.cg_key), ip }),
              );
              return;
            }
            confirmShow('서버를 등록하시겠습니까?', () => create({ group: Number(groupSelector.data.cg_key), ip }));
          }}
        >
          {typeof csKey === 'string' ? '수정' : '등록'}
        </button>
      </div>
      <GroupSelector />
    </Page>
  );
}

export default CdnServerManage;
