import axios from 'axios';

// const api = process.env.REACT_APP_API;
const api = process.env.PUBLIC_URL;

export const getVodList = async (e: number) => {
  try {
    const { data } = await axios.get(`${api}/trans/vod?page=${e}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};

export const getVodTransList = async () => {
  try {
    const { data } = await axios.get(`${api}/trans/vod/trans`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};

export const deleteVod = async (vodKey: number) => {
  await axios.delete(`${api}/trans/vod?vodKey=${vodKey}`, { withCredentials: true });
};

export const getVodTransInfo = async (vodKey: number) => {
  try {
    const { data } = await axios.get(`${api}/trans/progress?vodKey=${vodKey}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};
