function FormItem({
  title,
  essential,
  children,
  warning,
}: {
  title: string;
  essential: boolean;
  children: React.ReactNode | null;
  // eslint-disable-next-line react/require-default-props
  warning?: string;
}) {
  return (
    <div className="formItem">
      <div className="subject">
        <span>
          {title}
          {essential && <u>*</u>}
        </span>
        {warning && <div className="warning">{warning}</div>}
      </div>
      <div className="option">{children}</div>
    </div>
  );
}

export default FormItem;
