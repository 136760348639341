import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { login } from '../../api/login';
import { useAlert } from '../../stores/alertStore';
import styles from './login.module.scss';

// img
import logo from '../../assets/img/logo.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';

function Login() {
  const { pathname } = useLocation();
  const { alertShow } = useAlert();
  const [idValue, setIdValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [passView, setPassView] = useState(false);

  const idRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [, setCookie, removeCookie] = useCookies(['xtrans-id']);
  const { mutate } = useMutation(() => login({ id: idValue, pass: passValue }), {
    onSuccess: () => {
      setCookie('xtrans-id', idValue, { domain: '.xcast.co.kr', path: '/', expires: dayjs().add(6, 'hour').toDate() });
      window.postMessage('linkPage_/web');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  useEffect(() => {
    if (pathname === '/web/login') {
      const option = { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' };
      removeCookie('xtrans-id', option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <form className={styles.inner}>
        <img src={logo} alt="logo" className={styles.logo} />
        <div className={styles.form}>
          <div className={styles.input}>
            <input type="hidden" name="id" />
            <input type="hidden" name="password" />
          </div>
          <div className={styles.input}>
            <input
              type="text"
              value={idValue}
              onChange={e => setIdValue(e.target.value)}
              name="ID"
              autoComplete="off"
              placeholder="아이디"
              ref={idRef}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.code === 'Enter') passRef.current?.focus();
              }}
            />
          </div>
          <div className={styles.input}>
            <input
              type={passView ? 'text' : 'password'}
              value={passValue}
              onChange={e => setPassValue(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                  passRef.current?.blur();
                }
              }}
              name="PASSWORD"
              autoComplete="off"
              placeholder="비밀번호"
              ref={passRef}
              className={styles.password}
            />
            <img
              src={passView ? icVisibilityOff : icVisibility}
              alt="icon"
              className={styles.view}
              onClick={() => setPassView(prev => !prev)}
            />
          </div>
          <div
            className={styles.btn}
            onClick={() => {
              passRef.current?.blur();
              mutate();
            }}
            onKeyPress={e => {
              if (e.key === 'Enter' || e.code === 'Enter') {
                passRef.current?.blur();
                mutate();
              }
            }}
          >
            로그인
          </div>
          {/* <div className={styles.etc}>
                <div className={styles.label} onClick={() => loginStore.setKeepState(!loginStore.keepState)}>
                  <div className={classNames(styles.checkbox, loginStore.keepState && styles.selected)}></div>
                  <div className={styles.text}>로그인 상태 유지</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.element}>
                    <span>아이디/비밀번호 찾기</span>
                  </div>
                </div>
              </div> */}
        </div>
      </form>
    </div>
  );
}

export default Login;
