import { atom, useSetRecoilState } from 'recoil';

export const vodInfoState = atom<{ state: boolean; title: string; data: IVod }>({
  key: 'vodInfo',
  default: {
    state: false,
    title: '',
    data: {
      vod_key: 0,
      vod_name: '',
      vod_origin_name: '',
      vod_status: '',
      vod_file_type: '',
      vod_transcoder_ip: '',
      vod_path: '',
      created_at: '',
      updated_at: '',
    },
  },
});

export function useVodInfo() {
  const setVodInfoState = useSetRecoilState(vodInfoState);

  const setVodInfo = (data: { state: boolean; title: string; data: IVod }) =>
    setVodInfoState({ state: data.state, title: data.title, data: data.data });

  return { setVodInfo };
}

export const vodMetaState = atom<{ state: boolean; data: IVodMeta }>({
  key: 'vodMeta',
  default: {
    state: false,
    data: {
      title: '',
      path: '',
      regdate: '',
      register: '',
      discription: '',
      original: {
        vcodec: '',
        acodec: ',',
        type: '',
        resolution: { width: '', height: '' },
      },
      converted: [],
    },
  },
});

export function useVodMeta() {
  const setVodMetaState = useSetRecoilState(vodMetaState);

  const setVodMeta = (data: { state: boolean; data: IVodMeta }) =>
    setVodMetaState({ state: data.state, data: data.data });

  return { setVodMeta };
}

export const vodTransState = atom<{ state: boolean; data: IVodTransInfo[] }>({
  key: 'vodTrans',
  default: {
    state: false,
    data: [],
  },
});

export function useVodTrans() {
  const setVodTransState = useSetRecoilState(vodTransState);

  const setVodTrans = (data: { state: boolean; data: IVodTransInfo[] }) =>
    setVodTransState({ state: data.state, data: data.data });

  return { setVodTrans };
}
