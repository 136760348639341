import { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import queryString from 'query-string';
import styles from './login.module.scss';

// img
import icError from '../../assets/img/icons/ic_error.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';

function PasswordChange() {
  // const { search } = useLocation();
  // const { cmst, superadmin } = queryString.parse(search);

  const [passValue, setPassValue] = useState('');
  const [passView, setPassView] = useState(false);

  const [newPassValue, setNewPassValue] = useState('');
  const [newPassView, setNewPassView] = useState(false);

  const [confirmPassValue, setConfirmPassValue] = useState('');
  const [confirmPassView, setConfirmPassView] = useState(false);

  const [waring, setWarning] = useState(true);

  return (
    <div className={styles.container}>
      <form className={styles.inner}>
        <div className={styles.title}>
          <img src={icError} alt="icon" />
          <span>비밀번호를 변경해 주세요!</span>
        </div>
        <div className={styles.info}>
          안전한 사용을 위하여, 기존 비밀번호를 변경해야 합니다.
          <br />
          아래에 새 비밀번호를 입력해 주세요
        </div>
        <div className={styles.form}>
          <div className={styles.input}>
            <input type="hidden" name="password" />
            <input type="hidden" name="newPassword" />
            <input type="hidden" name="newPasswordConfirm" />
          </div>
          <div className={styles.input}>
            <input
              type={passView ? 'text' : 'password'}
              value={passValue}
              onChange={e => setPassValue(e.target.value)}
              name="PASSWORD"
              autoComplete="off"
              placeholder="현재 비밀번호"
              className={styles.password}
            />
            <img
              src={passView ? icVisibilityOff : icVisibility}
              alt="icon"
              className={styles.view}
              onClick={() => setPassView(prev => !prev)}
            />
          </div>
          <div className={styles.input}>
            <input
              type={newPassView ? 'text' : 'password'}
              value={newPassValue}
              onChange={e => {
                setNewPassValue(e.target.value);
                const numberCheck = /[0-9]/.test(e.target.value);
                const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                const spaceCheck = e.target.value.search(/\s/) === -1;
                const double =
                  (numberCheck && alphabetCheck) || (numberCheck && specialCheck) || (alphabetCheck && specialCheck);
                const triple = numberCheck && alphabetCheck && specialCheck;
                setWarning(
                  !spaceCheck ||
                    e.target.value.length < 8 ||
                    (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                    (e.target.value.length > 9 && !double),
                );
              }}
              name="NEWPASSWORD"
              autoComplete="off"
              placeholder="새 비밀번호"
              className={styles.password}
            />
            <img
              src={newPassView ? icVisibilityOff : icVisibility}
              alt="icon"
              className={styles.view}
              onClick={() => setNewPassView(prev => !prev)}
            />
          </div>
          <div className={styles.input}>
            <input
              type={confirmPassView ? 'text' : 'password'}
              value={confirmPassValue}
              onChange={e => {
                setConfirmPassValue(e.target.value);
                const numberCheck = /[0-9]/.test(e.target.value);
                const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                const spaceCheck = e.target.value.search(/\s/) === -1;
                const double =
                  (numberCheck && alphabetCheck) || (numberCheck && specialCheck) || (alphabetCheck && specialCheck);
                const triple = numberCheck && alphabetCheck && specialCheck;
                setWarning(
                  !spaceCheck ||
                    e.target.value.length < 8 ||
                    (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                    (e.target.value.length > 9 && !double),
                );
              }}
              name="CONFIRMPASSWORD"
              autoComplete="off"
              placeholder="비밀번호 확인"
              className={styles.password}
            />
            <img
              src={confirmPassView ? icVisibilityOff : icVisibility}
              alt="icon"
              className={styles.view}
              onClick={() => setConfirmPassView(prev => !prev)}
            />
          </div>
          {(newPassValue.length > 0 || confirmPassValue.length > 0) && (
            <div className={styles.guide}>
              {newPassValue !== confirmPassValue && (
                <div className={styles.item}>
                  <span>비밀번호가 일치하지 않습니다.</span>
                </div>
              )}
              {waring && (
                <div className={styles.item}>
                  <span>
                    비밀번호는 공백 없이 영문, 숫자, 특수문자를 모두 포함하여 8자 이상 또는 영문, 숫자, 특수문자 중
                    2가지를 조합하여 10자리 이상으로 입력하십시오.
                  </span>
                </div>
              )}
            </div>
          )}
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <div className={styles.btn} onClick={() => {}}>
            변경 완료
          </div>
        </div>
      </form>
    </div>
  );
}

export default PasswordChange;
