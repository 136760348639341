import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { foldState } from '../stores/layoutStore';
import styles from './header.module.scss';

// img
import icMenu from '../assets/img/icons/ic_menu.svg';
import logo from '../assets/img/logo.svg';
import icLogout from '../assets/img/icons/ic_logout.svg';

function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const setFold = useSetRecoilState(foldState);

  const [cookies] = useCookies(['xtrans-id']);

  useEffect(() => {
    if (cookies['xtrans-id'] === undefined) navigate('/web/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.contents}>
          <div className={styles.start}>
            <img src={icMenu} alt="icon" className={styles.menu} onClick={() => setFold(prev => !prev)} />
            <div className={styles.logo}>
              <img src={logo} alt="logo" onClick={() => navigate('/web')} />
            </div>
          </div>
          <div className={styles.center}>
            <div className={styles.passChange} onClick={() => navigate('/web/login/change')}>
              비밀번호 변경
            </div>
          </div>
          <div className={styles.end}>
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <div className={styles.logout} onClick={() => navigate('/web/login')}>
              <img src={icLogout} alt="icon" />
              <span>로그아웃</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
