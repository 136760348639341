import { createRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { foldState } from '../stores/layoutStore';
import styles from './main.module.scss';

function Main({ children }: { children: React.ReactNode }) {
  const fold = useRecoilValue(foldState);
  const mainRef = createRef<HTMLDivElement>();
  useEffect(() => {
    mainRef.current?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fold]);
  return (
    <div className={styles.container} ref={mainRef}>
      {children}
    </div>
  );
}

export default Main;
