import { atom, useSetRecoilState } from 'recoil';

export const groupSelectorState = atom<IGroupSelector>({
  key: 'groupSelector',
  default: {
    state: false,
    data: { cg_key: null, cg_name: '' },
  },
});

export function useGroupSelector() {
  const setGroupSelectorState = useSetRecoilState(groupSelectorState);

  const setGroupSelector = (data: IGroupSelector) => setGroupSelectorState({ state: data.state, data: data.data });

  return { setGroupSelector };
}
