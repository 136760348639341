import { atom, useRecoilState } from 'recoil';

interface IConfirm {
  state: boolean;
  message: string;
  func: (() => void) | null;
}

export const confirmState = atom<IConfirm>({
  key: 'confirm',
  default: {
    state: false,
    message: '',
    func: null,
  },
});

export function useConfirm() {
  const [confirm, setConfirm] = useRecoilState(confirmState);

  const confirmShow = (msg: string, fnc: (() => void) | null) => setConfirm({ state: true, message: msg, func: fnc });

  const confirmCheck = () => {
    if (confirm.func) confirm.func();
    setConfirm({ state: false, message: '', func: null });
  };

  const confirmClose = () => setConfirm({ state: false, message: '', func: null });

  return { confirmShow, confirmCheck, confirmClose };
}
