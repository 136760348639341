import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import queryString from 'query-string';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createGroup, getGroupDetail, patchGroup } from '../../api/cdn';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';

// components
import Page from '../../includes/Page';
import FormItem from '../../components/basic/FormItem';

function CdnGroupManage() {
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const { search } = useLocation();
  const { cgKey } = queryString.parse(search);

  const [domain, setDomain] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [locate, setLocate] = useState('');
  const [path, setPath] = useState('');

  const { isLoading: getLoading } = useQuery<IGroup>(['groupDetail'], () => getGroupDetail(Number(cgKey)), {
    refetchOnWindowFocus: false,
    enabled: typeof cgKey === 'string',
    onSuccess: e => {
      setDomain(e.cg_domain);
      setName(e.cg_name);
      setDescription(e.cg_description);
      setLocate(e.cg_locate);
      setPath(e.cg_path);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const init = () => {
    setDomain('');
    setName('');
    setDescription('');
    setLocate('');
    setPath('');
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create, isLoading: createLoading } = useMutation(createGroup, {
    onSuccess: () => alertShow('그룹이 등록되었습니다.', init),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(patchGroup, {
    onSuccess: () => alertShow('그룹이 수정되었습니다.'),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(getLoading || createLoading || updateLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLoading, createLoading, updateLoading]);

  return (
    <Page title={typeof cgKey === 'string' ? 'CDN그룹수정' : 'CDN그룹등록'}>
      <FormItem title="도메인" essential>
        <div className="input">
          <input type="text" placeholder="도메인" value={domain} onChange={e => setDomain(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="이름" essential>
        <div className="input">
          <input type="text" placeholder="이름" value={name} onChange={e => setName(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="설명" essential>
        <div className="input">
          <input type="text" placeholder="설명" value={description} onChange={e => setDescription(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="위치" essential>
        <div className="input">
          <input type="text" placeholder="위치" value={locate} onChange={e => setLocate(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="경로" essential>
        <div className="input">
          <input type="text" placeholder="경로" value={path} onChange={e => setPath(e.target.value)} />
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (domain === '' || name === '' || description === '' || locate === '' || path === '') {
              alertShow('빈 항목을 입력하십시오.');
              return;
            }
            if (typeof cgKey === 'string') {
              confirmShow('그룹을 수정하시겠습니까?', () =>
                update({ key: Number(cgKey), domain, name, description, locate, path }),
              );
              return;
            }
            confirmShow('그룹을 등록하시겠습니까?', () => create({ domain, name, description, locate, path }));
          }}
        >
          {typeof cgKey === 'string' ? '수정' : '등록'}
        </button>
      </div>
    </Page>
  );
}

export default CdnGroupManage;
