import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { vodMetaState, useVodMeta } from '../../stores/vodStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function VodMeta() {
  const vodInfo = useRecoilValue(vodMetaState);
  const { setVodMeta } = useVodMeta();

  const [tab, setTab] = useState('original');

  return (
    <AnimatePresence>
      {vodInfo.state && (
        <Modal
          close={() =>
            setVodMeta({
              state: false,
              data: {
                title: '',
                path: '',
                regdate: '',
                register: '',
                discription: '',
                original: {
                  vcodec: '',
                  acodec: ',',
                  type: '',
                  resolution: { width: '', height: '' },
                },
                converted: [],
              },
            })
          }
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.thumb}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                src={`https://${window.location.hostname.includes('demo') ? 'demo' : ''}xvodcdn.xcast.co.kr/asset/${
                  vodInfo.data?.path
                }/${vodInfo.data?.title}/preview.mp4`}
                autoPlay
                muted
                loop
              />
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>파일명</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data?.title}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>경로</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data?.path}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>등록일</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data?.regdate}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>등록자</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data?.register}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>설명</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data?.discription}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>비고</span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.switch}>
                      <div className={styles.tab}>
                        <div
                          className={classNames(styles.item, tab === 'original' && styles.active)}
                          onClick={() => setTab('original')}
                        >
                          원본
                        </div>
                        {Array.from(new Set(vodInfo.data?.converted?.map(item => item.vcodec))).map(ele => (
                          <div
                            className={classNames(styles.item, tab === ele && styles.active)}
                            onClick={() => setTab(ele || 'original')}
                            key={ele}
                          >
                            {ele}
                          </div>
                        ))}
                      </div>
                      <table>
                        <thead>
                          <tr>
                            {tab === 'original' && (
                              <th>{vodInfo.data?.original?.video !== undefined ? 'codec' : 'vcodec'}</th>
                            )}
                            <th>
                              {tab === 'original' && vodInfo.data?.original?.video !== undefined
                                ? 'originEncoder'
                                : 'acodec'}
                            </th>
                            {tab !== 'original' && <th>spec</th>}
                            <th>resolution</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ display: tab === 'original' ? '' : 'none' }}>
                            <td>
                              {vodInfo.data?.original?.video !== undefined
                                ? vodInfo.data?.original?.video?.codec
                                : vodInfo.data?.original.vcodec}
                            </td>
                            <td>
                              {tab === 'original' && vodInfo.data?.original?.video !== undefined
                                ? vodInfo.data?.original.originEncoder
                                : vodInfo.data?.original.acodec}
                            </td>
                            <td>
                              {tab === 'original' && vodInfo.data?.original?.video !== undefined ? (
                                <>
                                  {vodInfo.data?.original?.video?.width || 0} *{' '}
                                  {vodInfo.data?.original?.video?.height || 0}
                                </>
                              ) : (
                                <>
                                  {vodInfo.data?.original?.resolution?.width || 0} *{' '}
                                  {vodInfo.data?.original?.resolution?.height || 0}
                                </>
                              )}
                            </td>
                          </tr>
                          {vodInfo.data?.converted
                            ?.filter(item => item.vcodec === tab)
                            .map(item => (
                              <tr key={`${item.vcodec}(${item.resolution?.width}x${item.resolution?.height})`}>
                                <td>{item.acodec}</td>
                                <td>{item.spec}</td>
                                <td>
                                  {item.resolution?.width || 0} * {item.resolution?.height || 0}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default VodMeta;
