import { useRecoilValue } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';
import { alertState, useAlert } from '../../stores/alertStore';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Alert() {
  const alert = useRecoilValue(alertState);
  const { alertClose } = useAlert();
  return (
    <AnimatePresence>
      {alert.state && (
        <Modal close={() => alertClose()}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>알림</div>
            <div className={styles.body}>
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: alert.message }}></div>
              <div className={styles.btns}>
                <div className={styles.btn} onClick={() => alertClose()}>
                  확인
                </div>
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}
export default Alert;
