import styles from './page.module.scss';

function Page({ title, children }: { title: string; children: React.ReactNode | null }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default Page;
