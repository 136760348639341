import axios from 'axios';

// const api = process.env.REACT_APP_API;
const api = process.env.PUBLIC_URL;

export const login = async (element: { id: string; pass: string }) => {
  await axios.post(
    `${api}/user/login`,
    { id: element.id, pass: element.pass },
    {
      withCredentials: true,
    },
  );
};
