import { useRecoilValue } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { vodInfoState, useVodInfo } from '../../stores/vodStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function VodInfo() {
  const vodInfo = useRecoilValue(vodInfoState);
  const { setVodInfo } = useVodInfo();

  return (
    <AnimatePresence>
      {vodInfo.state && (
        <Modal
          close={() =>
            setVodInfo({
              state: false,
              title: '',
              data: {
                vod_key: 0,
                vod_name: '',
                vod_origin_name: '',
                vod_status: '',
                vod_file_type: '',
                vod_transcoder_ip: '',
                vod_path: '',
                created_at: '',
                updated_at: '',
              },
            })
          }
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>{vodInfo.title}</div>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>파일명</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data.vod_origin_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>이름</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data.vod_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>상태</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {vodInfo.data.vod_status === 'ready'
                          ? '대기'
                          : vodInfo.data.vod_status === 'transcoding'
                          ? '변환중'
                          : '완료'}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>확장자</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data.vod_file_type}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>IP</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data.vod_transcoder_ip}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>경로</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{vodInfo.data.vod_path}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>등록</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{moment(vodInfo.data.created_at).format('YYYY-MM-DD hh:mm')}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500' }}>업데이트</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{moment(vodInfo.data.updated_at).format('YYYY-MM-DD hh:mm')}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default VodInfo;
