import { useRecoilValue } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';
import { confirmState, useConfirm } from '../../stores/confirmStore';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Confirm() {
  const confirm = useRecoilValue(confirmState);
  const { confirmCheck, confirmClose } = useConfirm();
  return (
    <AnimatePresence>
      {confirm.state && (
        <Modal close={() => confirmClose()}>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>알림</div>
            <div className={styles.body}>
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: confirm.message }}></div>
              <div className={styles.btns}>
                <div className={styles.btn} onClick={() => confirmCheck()}>
                  확인
                </div>
                <div className={styles.btn} onClick={() => confirmClose()}>
                  취소
                </div>
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Confirm;
