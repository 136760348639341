import { useNavigate } from 'react-router-dom';
import styles from './pageNotFound.module.scss';

// img
import logo from '../../assets/img/logo.svg';
import imgTemi from '../../assets/img/images/img_temi.svg';

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={styles.message}>
          <div className={styles.title}>요청하신 페이지를 찾을 수 없습니다.</div>
          찾으려는 페이지의 주소가 잘못 입력 되었거나, 주소의 변경 혹은 삭제로 인해 이용하실 수 없습니다. 올바른 URL
          주소를 입력하셨는지 확인해 주시기 바랍니다.
        </div>
        <div className={styles.btn} onClick={() => navigate('/web')}>
          <span>홈 가기</span>
        </div>
      </div>
      <img src={imgTemi} alt="alt" className={styles.temi} />
    </div>
  );
}

export default PageNotFound;
