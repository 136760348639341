import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';
import { loadingState } from '../../stores/loadingStore';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/Page';
import FormItem from '../../components/basic/FormItem';

// const api = process.env.REACT_APP_API;
const api = process.env.PUBLIC_URL;

function VodManage() {
  const { search } = useLocation();

  const setLoading = useSetRecoilState(loadingState);
  const [directory, setDirectory] = useState('');

  const init = () => setDirectory('');
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadVideo, setUploadVideo] = useState<string | null>(null);
  const [uploadVideoName, setUploadVideoName] = useState('');
  const changeVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setUploadFile(null);
      setUploadVideo(null);
      setUploadVideoName('');
      return;
    }
    const maxSize = 1024 ** 3;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 동영상의 사이즈는 1GB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension;
    };
    if (
      getExtension(event.target.files[0].name) !== '.mp4' &&
      getExtension(event.target.files[0].name) !== '.mkv' &&
      getExtension(event.target.files[0].name) !== '.webm'
    ) {
      console.log(event.target.files[0].name);
      alertShow('mp4, mkv, webm 확장자를 가진 동영상 파일만 등록할 수 있습니다.');
      return;
    }
    setUploadFile(event.target.files[0]);
    setUploadVideo(URL.createObjectURL(event.target.files[0]));
    setUploadVideoName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => console.log(event);
      reader.onerror = error => console.log(error);
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const complete = async (dir: string, name: string) => {
    try {
      await axios.post(
        `${api}/trans/complete`,
        { dir, name, originName: uploadVideoName, typeCode: 'xcast', code: 'transpage' },
        { withCredentials: true },
      );
      window.postMessage('linkPage_/web/vod');
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, dir: string, name: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        console.log(data);
        complete(dir, name);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const dirPost = async (dir: string) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${api}/trans/upload`,
        { dir, typeCode: 'xcast', code: 'transpage' },
        { withCredentials: true },
      );
      await fileUpload(data.url.url, formData(data.url.fields, uploadFile), dir, data.name);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };
  const create = () => {
    if (directory === '') {
      alertShow('경로를 입력하세요.');
      return;
    }
    if (uploadVideo === null) {
      alertShow('비디오 파일을 등록하세요.');
      return;
    }
    const dir = directory.substring(0, 1) === '/' ? directory.slice(1) : directory;
    confirmShow('VOD를 등록하시겠습니까?', () => dirPost(dir));
  };

  return (
    <Page title="VOD업로드">
      <FormItem title="경로" essential>
        <div className="input">
          <input
            type="text"
            placeholder="경로"
            value={directory}
            onChange={e => {
              const specialCheck = /[:*"<>|]/.test(e.target.value);
              if (/\s/.test(e.target.value)) return;
              if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(e.target.value)) return;
              if (e.target.value !== '' && specialCheck) return;
              if (e.target.value.substring(0, 1) === '/') return;
              setDirectory(e.target.value);
            }}
          />
        </div>
      </FormItem>
      <FormItem title="VOD" essential warning="mp4, mkv, webm 확장자를 가진 동영상 파일">
        <div className="file">
          <label htmlFor="img-upload">
            {uploadVideoName === '' ? (
              <div className="placeholder">VOD 업로드 (mp4, mkv, webm 확장자를 가진 동영상 파일)</div>
            ) : (
              <div className="text">{uploadVideoName}</div>
            )}
            <input type="file" id="img-upload" onChange={e => changeVideo(e)} accept="video/*,.mkv" />
          </label>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (directory === '') {
              alertShow('빈 항목을 입력하십시오.');
              return;
            }
            create();
          }}
        >
          등록
        </button>
      </div>
    </Page>
  );
}

export default VodManage;
