import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import styles from "./aside.module.scss";

function AsideItem({
  title,
  icon,
  active,
  children,
  link,
}: {
  title: string;
  icon: string;
  active: boolean;
  children: React.ReactNode | null;
  link?: string;
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const menuRef = createRef<HTMLDivElement>();
  const openMenu = (event: React.MouseEvent) => {
    if (link) {
      navigate(link);
      return;
    }
    setOpen((prev) => !prev);
    if (open) {
      setHeight(0);
    } else {
      const itemCount =
        event.currentTarget.parentElement?.children[1].childElementCount;
      setHeight(itemCount || 0);
    }
  };

  const openRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (active) openRef?.current?.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("message", (message) => {
      if (typeof message.data === "string" && message.data === "menuFold") {
        setOpen(false);
        setHeight(0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.menu} ref={menuRef}>
      <div
        className={classNames(
          styles.subject,
          open && styles.active,
          link && styles.link
        )}
        onClick={openMenu}
        ref={openRef}
      >
        <img src={icon} alt="icon" />
        <span style={{ color: active ? "var(--primary)" : "" }}>{title}</span>
      </div>
      <div
        className={styles.submenu}
        style={{
          height: open ? `calc(40px * ${height})` : "",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default AsideItem;
