import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { getGroupList } from '../../api/cdn';
import { loadingState } from '../../stores/loadingStore';
import { useAlert } from '../../stores/alertStore';
import { groupSelectorState, useGroupSelector } from '../../stores/cdnStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function GroupSelector() {
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { data, isLoading } = useQuery<IGroup[]>(['group'], getGroupList, {
    refetchOnWindowFocus: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const groupSelector = useRecoilValue(groupSelectorState);
  const { setGroupSelector } = useGroupSelector();

  return (
    <AnimatePresence>
      {groupSelector.state && (
        <Modal
          close={() =>
            setGroupSelector({
              ...groupSelector,
              state: false,
            })
          }
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>그룹을 선택하십시오.</div>
            <table className="table">
              <thead>
                <tr>
                  <th>도메인</th>
                  <th>이름</th>
                  <th>설명</th>
                  <th>위치</th>
                  <th>경로</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div>검색된 그룹이 없습니다.</div>
                    </td>
                  </tr>
                )}
                {data?.map(item => (
                  <tr key={item.cg_key}>
                    <td>
                      <div>
                        <span>{item.cg_domain}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.cg_name}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.cg_description}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.cg_locate}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.cg_path}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button
                          type="button"
                          className="success"
                          onClick={() =>
                            setGroupSelector({ state: false, data: { cg_key: item.cg_key, cg_name: item.cg_name } })
                          }
                        >
                          <span>선택</span>
                        </button>
                        <button
                          type="button"
                          className="primary"
                          onClick={() => navigate(`/web/cdn/groupManage?cgKey=${item.cg_key}`)}
                        >
                          <span>수정</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default GroupSelector;
