import axios from 'axios';

// const api = process.env.REACT_APP_API;
const api = process.env.PUBLIC_URL;

// cdn그룹
export const getGroupList = async () => {
  try {
    const { data } = await axios.get(`${api}/cdn/group`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};
export const getGroupDetail = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/cdn/group/{key}?key=${key}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};
export const createGroup = async (data: {
  domain: string;
  name: string;
  description: string;
  locate: string;
  path: string;
}) => {
  await axios.post(
    `${api}/cdn/group`,
    {
      domain: data.domain,
      name: data.name,
      description: data.description,
      locate: data.locate,
      path: data.path,
    },
    {
      withCredentials: true,
    },
  );
};
export const patchGroup = async (data: {
  key: number;
  domain: string;
  name: string;
  description: string;
  locate: string;
  path: string;
}) => {
  await axios.patch(
    `${api}/cdn/group/${data.key}`,
    {
      domain: data.domain,
      name: data.name,
      description: data.description,
      locate: data.locate,
      path: data.path,
    },
    {
      withCredentials: true,
    },
  );
};
export const deleteGroup = async (data: { key: number; chain?: boolean }) => {
  await axios.delete(`${api}/cdn/group/${data.key}`, {
    withCredentials: true,
  });
};

// cdn서버
export const getServerList = async (group: number) => {
  if (group === 0) return [];
  try {
    const { data } = await axios.get(`${api}/cdn/server?group=${group}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};
export const getServerDetail = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/cdn/server/{key}?key=${key}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.data.statusCode === 401) window.location.href = '/web/login';
    return null;
  }
};
export const createServer = async (data: { group: number; ip: string }) => {
  await axios.post(
    `${api}/cdn/server`,
    { group: data.group, ip: data.ip },
    {
      withCredentials: true,
    },
  );
};
export const patchServer = async (data: { key: number; group: number; ip: string }) => {
  await axios.patch(
    `${api}/cdn/server/${data.key}`,
    {
      group: data.group,
      ip: data.ip,
    },
    {
      withCredentials: true,
    },
  );
};
export const deleteServer = async (data: { key: number; chain?: boolean }) => {
  await axios.delete(`${api}/cdn/server/${data.key}`, {
    withCredentials: true,
  });
};
