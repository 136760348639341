import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './content.module.scss';

function Content({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.addEventListener('message', message => {
      if (typeof message.data === 'string' && message.data === 'contentScollTop') {
        contentRef.current?.scrollTo(0, 0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    contentRef.current?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <div className={styles.container} ref={contentRef}>
      {children}
    </div>
  );
}

export default Content;
