import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useVodTrans, vodTransState } from '../../stores/vodStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function VodProgress() {
  const vodTrans = useRecoilValue(vodTransState);
  const { setVodTrans } = useVodTrans();

  const [tab, setTab] = useState('');

  useEffect(() => {
    if (vodTrans.data.length > 0 && vodTrans.data[0].vodtspr_codec) setTab(vodTrans.data[0].vodtspr_codec);
  }, [vodTrans.data]);

  return (
    <AnimatePresence>
      {vodTrans.state && (
        <Modal
          close={() =>
            setVodTrans({
              state: false,
              data: [],
            })
          }
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ type: 'linear' }}
            exit={{ scale: 0 }}
            className={styles.modal}
          >
            <div className={styles.title}>변환중</div>
            <div className={classNames(styles.switch, styles.out)}>
              <table className="table" style={{ marginTop: 'unset' }}>
                <tbody>
                  {vodTrans.data.map(item => (
                    <tr key={item.vodtspr_key}>
                      <td style={{ backgroundColor: 'white' }}>
                        <div className={styles.subject}>
                          <span>{item.vodtspr_codec}</span>
                        </div>
                      </td>
                      <td>
                        <div className={styles.subject}>
                          <span>{item.vodtspr_resolution}</span>
                        </div>
                      </td>
                      <td style={{ backgroundColor: item.vodtspr_percent === 100 ? 'var(--hover)' : '' }}>
                        <div>
                          <div className={styles.progress}>
                            <div
                              className={classNames(styles.gauge, item.vodtspr_percent === 100 && styles.complete)}
                              style={{ width: `${item.vodtspr_percent}%` }}
                            ></div>
                          </div>
                          <div className={styles.percent}>{item.vodtspr_percent}%</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default VodProgress;
