import { createRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import styles from './aside.module.scss';
import { foldState } from '../stores/layoutStore';

// components
import AsideItem from '../components/aside/AsideItem';

// img
import icServer from '../assets/img/icons/ic_server.svg';
import icServerActive from '../assets/img/icons/ic_server_active.svg';
import icVideo from '../assets/img/icons/ic_video.svg';
import icVideoActive from '../assets/img/icons/ic_video_active.svg';
import { useCookies } from 'react-cookie';

function Aside() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const asideRef = createRef<HTMLDivElement>();
  const asideScroll = (e: number) => {
    setTimeout(() => {
      asideRef.current?.scrollTo({ top: e, left: 0, behavior: 'smooth' });
    }, 200);
  };

  const fold = useRecoilValue(foldState);

  // const mstAccess = loginStore.access?.split(',').includes('mst');
  // const allAccess = !mstAccess && loginStore.access?.split(',').includes('all');
  // const grAccess = !mstAccess && loginStore.access?.split(',').includes('gr');
  // const amAccess = !mstAccess && loginStore.access?.split(',').includes('am');
  // const cstAccess = !mstAccess && loginStore.access?.split(',').includes('cst');
  // const svAccess = !mstAccess && loginStore.access?.split(',').includes('sv');
  // const rvAccess = !mstAccess && loginStore.access?.split(',').includes('rv');
  // const rbAccess = !mstAccess && loginStore.access?.split(',').includes('rb');
  // const chartAccess = !mstAccess && loginStore.access?.split(',').includes('chart');
  // const menuAccess = !mstAccess && loginStore.access?.split(',').includes('menu');

  // useEffect(() => {
  //   if (pathname === 'login') return;
  //   if (!mstAccess && pathname.includes('/mastergroup')) navigate('/');
  //   if (!(allAccess || grAccess) && pathname.includes('/group')) navigate('/');
  //   if (!(allAccess || amAccess) && pathname.includes('/manager')) navigate('/');
  //   if (!(allAccess || cstAccess) && pathname.includes('/consult')) navigate('/');
  //   if (
  //     !(allAccess || svAccess) &&
  //     (pathname.includes('/survey/manage') ||
  //       pathname.includes('/survey/response') ||
  //       pathname.includes('/survey/analysis'))
  //   )
  //     navigate('/');
  //   if (!(allAccess || rvAccess) && pathname.includes('/status')) navigate('/');
  //   if (!(allAccess || rbAccess) && pathname.includes('/robot')) navigate('/');
  //   // if (!(allAccess || chartAccess) && pathname.includes('/chart') && pathname !== '/login') navigate('/');
  //   if (!(mstAccess || allAccess) && pathname.includes('/chart')) navigate('/');
  //   if (!(mstAccess || allAccess || menuAccess) && pathname.includes('/menu')) navigate('/');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const [cookies] = useCookies(['ui-cdn']);

  return (
    <div className={classNames(styles.container, fold && styles.hide)} ref={asideRef}>
      {cookies['ui-cdn'] !== 'false' && (
        <AsideItem
          title="CDN"
          icon={pathname.includes('/web/cdn') ? icServerActive : icServer}
          active={pathname.includes('/web/cdn')}
        >
          <div
            className={classNames(styles.item, pathname === '/web/cdn' && styles.active)}
            onClick={() => navigate('/web/cdn')}
          >
            <span>서버목록</span>
          </div>
          <div
            className={classNames(styles.item, pathname === '/web/cdn/groupManage' && styles.active)}
            onClick={() => navigate('/web/cdn/groupManage')}
          >
            <span>그룹등록</span>
          </div>
          <div
            className={classNames(styles.item, pathname === '/web/cdn/serverManage' && styles.active)}
            onClick={() => navigate('/web/cdn/serverManage')}
          >
            <span>서버등록</span>
          </div>
        </AsideItem>
      )}
      <AsideItem
        title="VOD"
        icon={pathname.includes('/vod') ? icVideoActive : icVideo}
        active={pathname.includes('/vod')}
      >
        <div
          className={classNames(styles.item, pathname === '/web/vod' && styles.active)}
          onClick={() => navigate('/web/vod')}
        >
          <span>VOD목록</span>
        </div>
        <div
          className={classNames(styles.item, pathname === '/web/vod/trans' && styles.active)}
          onClick={() => navigate('/web/vod/trans')}
        >
          <span>변환중 VOD</span>
        </div>
      </AsideItem>
    </div>
  );
}

export default Aside;
