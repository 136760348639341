import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getVodList, deleteVod } from '../../api/vod';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { useVodInfo, useVodMeta, useVodTrans } from '../../stores/vodStore';

// components
import Page from '../../includes/Page';
import Pagination from '../../includes/Pagination';
import VodInfo from '../../components/vod/vodInfo';
import VodMeta from '../../components/vod/vodMeta';
import VodProgress from '../../components/vod/vodProgress';

// img
import icSearch from '../../assets/img/icons/ic_search.svg';
import icInfo from '../../assets/img/icons/ic_info.svg';
import icInfoTrans from '../../assets/img/icons/ic_info_trans.svg';
import icPlay from '../../assets/img/icons/ic_play.svg';

function Vod() {
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [data, setData] = useState<IVod[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const { isLoading, refetch } = useQuery<{ count: number; result: IVod[] }>(['vod', page], () => getVodList(page), {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setData(e.result.map(item => ({ ...item, checked: false })));
      setTotal(e.count);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => alertShow(error.response.data.message),
  });
  const { mutate } = useMutation(deleteVod, {
    onSuccess() {
      refetch();
    },
  });

  const onDelete = (vodKey: number) => confirmShow('VOD를 삭제하시겠습니까?', () => mutate(vodKey));

  const selectDelete = () => {
    confirmShow('선택한 그룹을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      checked.forEach(element => {
        mutate(element.vod_key);
      });
    });
  };

  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const allSelect = () => {
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );
  };

  const { setVodInfo } = useVodInfo();

  const { setVodMeta } = useVodMeta();

  const getVodMeta = async (path: string, name: string) => {
    try {
      const { data } = await axios.get(
        `https://${
          window.location.hostname.includes('demo') ? 'demo' : ''
        }xvodcdn.xcast.co.kr/asset/${path}/${name}/voddata.json`,
        {
          responseType: 'json',
        },
      );
      setVodMeta({ state: true, data });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alertShow(error.message);
    }
  };

  const { setVodTrans } = useVodTrans();

  const getVodTransInfo = async (vodKey: number) => {
    try {
      const { data } = await axios.get(`${process.env.PUBLIC_URL}/trans/progress?vodKey=${vodKey}`, {
        withCredentials: true,
      });
      if (data.length === 0) {
        alertShow('변환상태 수집중입니다.');
        return;
      }
      setVodTrans({ state: true, data });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alertShow(error.message);
    }
  };

  return (
    <Page title="VOD목록">
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>전체선택</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate('/web/vod/manage')}>
            <span>등록</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>선택삭제</span>
          </button>
        </div>
        <div className="search">
          <input type="text" placeholder="검색어를 입력하세요." />
          <img src={icSearch} alt="icon" />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th></th>
            <th>파일명</th>
            <th>상태</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div>검색된 VOD가 없습니다.</div>
              </td>
            </tr>
          )}
          {data.map(item => (
            <tr key={item.vod_key}>
              <td>
                <div>
                  <div
                    className={classNames(
                      'checkbox',
                      data.filter(ele => ele.vod_key === item?.vod_key)[0]?.checked && 'active',
                    )}
                    onClick={() => {
                      setData(
                        data.map(ele => {
                          if (ele.vod_key === item.vod_key) return { ...ele, checked: !ele.checked };
                          return ele;
                        }),
                      );
                    }}
                  ></div>
                </div>
              </td>
              <td>
                <div>
                  <span
                    style={{ cursor: 'pointer', color: 'var(--tertiary)' }}
                    onClick={() =>
                      setVodInfo({
                        state: true,
                        title: 'VOD 정보',
                        data: {
                          vod_key: item.vod_key,
                          vod_name: item.vod_name,
                          vod_origin_name: item.vod_origin_name,
                          vod_status: item.vod_status,
                          vod_file_type: item.vod_file_type,
                          vod_transcoder_ip: item.vod_transcoder_ip,
                          vod_path: item.vod_path,
                          created_at: item.created_at,
                          updated_at: item.updated_at,
                        },
                      })
                    }
                  >
                    {item.vod_origin_name}
                  </span>
                </div>
              </td>
              <td>
                <div>
                  {item.vod_status === 'ready' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--btn-success)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      대기
                    </span>
                  )}
                  {item.vod_status === 'transcoding' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--btn-warning)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      변환중
                    </span>
                  )}
                  {item.vod_status === 'complete' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--greyish6)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      완료
                    </span>
                  )}
                  {item.vod_status === 'error' && (
                    <span
                      style={{
                        maxWidth: '54px',
                        backgroundColor: 'var(--btn-danger)',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      에러
                    </span>
                  )}
                </div>
              </td>
              <td>
                <div>
                  {item.vod_status === 'complete' ? (
                    <>
                      <img
                        src={icPlay}
                        alt="icon"
                        className="icon"
                        onClick={() => window.open(`${process.env.PUBLIC_URL}/tp?vod_key=${item.vod_key}`)}
                      />
                      <img
                        src={icInfo}
                        alt="icon"
                        onClick={() => getVodMeta(item.vod_path, item.vod_name)}
                        className="icon"
                      />
                    </>
                  ) : item.vod_status === 'transcoding' ? (
                    <>
                      <img src={icPlay} alt="icon" className="icon disabled" />
                      <img
                        src={icInfoTrans}
                        alt="icon"
                        className="icon"
                        onClick={() => getVodTransInfo(item.vod_key)}
                      />
                    </>
                  ) : (
                    <>
                      <img src={icPlay} alt="icon" className="icon disabled" />
                      <img src={icInfo} alt="icon" className="icon disabled" />
                    </>
                  )}
                  <button type="button" className="secondary" onClick={() => onDelete(item.vod_key)}>
                    <span>삭제</span>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination total={total} movePage={e => setPage(e)} />
      <VodInfo />
      <VodMeta />
      <VodProgress />
    </Page>
  );
}

export default Vod;
