// img
import { useEffect } from 'react';
import logo from '../assets/img/logo.svg';

function Message() {
  useEffect(() => {
    window.postMessage('menuFold');
  }, []);
  return (
    <div style={{ width: '100%', height: '100%', minHeight: 'calc(100vh - 89px)', display: 'flex' }}>
      <img src={logo} alt="logo" style={{ margin: 'auto' }} />
    </div>
  );
}

export default Message;
